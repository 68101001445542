/*
 * Let's Play Bingo 90 Ball
 * Version 1.0
 * App written by Karol Brennan
 * https://karol.dev
 * http://github.com/karolbrennan
 */
// Dependencies
import React, {Component} from 'react';
import Slider from 'rc-slider';
import Select from 'react-select';

// Custom Components
import moedasmp3 from '../chimes/coins.mp3';
import BingoBoard from './subcomponents/BingoBoard.js';
import CallHistory from './subcomponents/CallHistory.js';


// Utilities
import { generateBingoBoard, getRandomBingoNumber, getBallDisplay, getLogoBallDisplay, getLanguageText, getPhrase} from '../utils.js';

// Chimes
import {chime1, chime2, chime3, chime4, chime5, chime6, chime7, chime8, chime9, chime10, chime11, chime12, pop1, pop2, pop3, pop4} from '../chimes';
class BingoGame extends Component {
  
  constructor(props) {
    super(props);
    // -------------------------- Set properties ----- //
    // Balls display pieces
    this.totalBallsCalled = 0;
    this.previousBall = null;
    this.currentBall = null;
    this.interval = null;
    this.chimes = [
      {label: 'Chime 1', value: chime1},
      {label: 'Chime 2', value: chime2},
      {label: 'Chime 3', value: chime3},
      {label: 'Chime 4', value: chime4},
      {label: 'Chime 5', value: chime5},
      {label: 'Chime 6', value: chime6},
      {label: 'Chime 7', value: chime7},
      {label: 'Chime 8', value: chime8},
      {label: 'Chime 9', value: chime9},
      {label: 'Chime 10', value: chime10},
      {label: 'Chime 11', value: chime11},
      {label: 'Chime 12', value: chime12},
      {label: 'Pop 1', value: pop1},
      {label: 'Pop 2', value: pop2},
      {label: 'Pop 3', value: pop3},
      {label: 'Pop 4', value: pop4}
    ];

    this.primeiravez = true;
    window.winners = [];
    this.chamadas = [];
    this.cartelas = [
      ['A1', 23,38,43,69,82,7,10,25,30,83,6,14,49,53,64],
      ['A2', 26,33,54,60,72,1,40,56,77,88,19,36,44,62,90],
      ['A3', 15,48,50,78,81,11,20,63,71,84,5,24,31,57,68],
      ['A4', 16,41,55,65,73,27,37,61,76,80,3,34,45,79,86],
      ['A5', 21,32,47,66,85,9,17,29,51,75,2,12,35,58,89],
      ['B1', 25,42,57,71,90,8,19,32,64,83,10,21,35,59,76],
      ['B2', 26,47,67,73,86,5,20,53,72,81,33,51,60,77,85],
      ['B3', 27,41,54,63,87,6,11,37,46,62,1,15,22,52,70],
      ['B4', 28,38,66,74,85,2,12,23,31,56,7,16,39,68,80],
      ['B6', 30,40,55,79,89,4,18,34,44,58,14,49,50,69,88],
      ['C1', 19,29,40,63,82,10,38,54,67,75,3,45,55,74,90],
      ['C2', 20,30,59,71,85,4,26,36,68,88,9,12,47,56,65],
      ['C3', 21,31,50,62,89,5,11,37,42,73,18,39,43,61,84],
      ['C4', 22,32,41,60,81,2,13,27,46,77,7,17,52,78,80],
      ['C5', 23,33,48,64,87,8,16,35,51,76,15,28,57,72,83],
      ['C6', 24,34,53,79,86,1,14,49,66,70,6,25,44,58,69],
      ['D1', 43,50,62,73,89,11,21,37,61,84,5,18,30,42,70],
      ['D2', 46,52,60,78,80,7,13,27,34,72,2,17,22,36,81],
      ['D3', 45,54,63,75,90,3,19,31,67,82,10,28,40,55,77],
      ['D4', 29,43,61,75,82,3,13,24,48,65,9,17,36,45,78],
      ['D5', 48,51,64,76,83,15,23,35,57,87,8,16,29,32,41],
      ['E1', 47,56,65,71,85,4,20,33,68,88,9,12,26,38,59],
      ['E2', 13,42,57,64,86,5,52,61,74,80,3,16,21,47,59],
      ['E3', 14,25,38,50,70,7,10,44,54,77,35,49,65,79,88],
      ['E4', 15,26,48,62,71,8,31,40,76,89,22,39,53,69,83],
      ['E5', 20,33,56,72,85,1,12,51,67,90,19,28,37,46,78],
      ['E6', 11,29,32,60,81,9,17,41,58,73,4,23,45,68,84],
      ['F1', 1,11,39,59,84,17,28,44,61,73,23,49,56,67,80],
      ['F2', 2,12,36,51,71,18,24,48,68,87,31,46,58,70,89],
      ['F3', 3,13,38,45,83,19,20,41,53,74,29,33,66,79,86],
      ['F4', 4,14,26,50,85,9,32,40,60,77,7,37,65,78,82],
      ['F5', 5,15,35,55,72,27,47,52,64,81,25,34,62,76,88],
      ['F6', 6,16,43,63,90,8,21,30,57,69,10,22,42,54,75],
      ['G1', 31,47,66,70,83,6,10,22,63,77,17,38,40,56,82],
      ['G2', 46,58,62,79,81,7,11,21,32,78,2,13,28,36,80],
      ['G3', 33,42,64,75,80,3,12,21,54,60,19,28,37,78,86],
      ['G5', 35,48,53,71,87,2,13,27,41,89,5,15,30,55,74],
      ['H1', 43,52,60,70,84,18,27,34,61,89,5,15,37,42,72],
      ['H5', 47,53,64,71,85,9,22,33,65,86,4,14,20,38,54]
  ];

    // Speech Synthesis
    this.speechEnabled = Object.prototype.hasOwnProperty.call(window,'speechSynthesis');
    this.synth = window.speechSynthesis;

    // if speech is enabled, initialize other speech properties
    if (this.speechEnabled === true) {
      this.synth.onvoiceschanged = this.loadVoices;
      this.voices = this.synth.getVoices();
    }

    let gameData = JSON.parse(localStorage.getItem('lpb90-gameData'));
    let gameState = JSON.parse(localStorage.getItem('lpb90-gameState'));

    if(gameData && gameState){
      for(let key in gameData){
        this[key] = gameData[key];
      }
      this.state = gameState;
    } else {
      // Set initial state
      this.state = this.getInitialStateData();
    }
  }

  getInitialStateData() {
    return {
      board: generateBingoBoard(),
      previousCallList: [],
      displayBoardOnly: false,
      delay: 8000,
      running: false,
      enableCaller: true,
      wildBingo: false,
      evensOdds: false,
      doubleCall: false,
      extraTalk: false,
      chime: true,
      selectedChime: this.chimes[0],
      selectedCaller: 4,
      showResetModal: false
    };
  }

  

  /**
   * In case of going from one page to another, when we return
   * and the component has mounted reinitialize the game from
   * local storage.
   *
   */
  componentDidMount(){
    this.loadVoices();
    // ensure the reset modal doesn't show at initial load
    this.setState({showResetModal: false});
  }

  /**
   * [componentDidUpdate description]
   *
   * @param   {[type]}  prevProps  [prevProps description]
   * @param   {[type]}  state      [state description]
   *
   * @return  {[type]}             [return description]
   */
  componentDidUpdate(prevProps,state){
    let gameData = {
      totalBallsCalled: this.totalBallsCalled,
      previousBall: this.previousBall,
      currentBall: this.currentBall,
      interval: this.interval
    }
    localStorage.setItem('lpb90-gameData', JSON.stringify(gameData));
    localStorage.setItem('lpb90-gameState', JSON.stringify(this.state));
  }

  /**
   * [initializeFromLocalStorage description]
   *
   * @return  {[type]}  [return description]
   */
  initializeFromLocalStorage = () => {
    let gameData = JSON.parse(localStorage.getItem('lpb90-gameData'));
    let gameState = JSON.parse(localStorage.getItem('lpb90-gameState'));
    if(gameData && gameState){
      for(let key in gameData){
        this[key] = gameData[key];
      }
      this.setState(...gameState);
    }
  }

  /* ------------------- Speech Synthesis Functions */
  /*
   *  Load Voices Function
   *  Will load voices as they change within the browser
   */
  loadVoices = () => {
    this.voices = this.synth.getVoices();
    let selectedCaller = this.state.selectedCaller;
    if(selectedCaller === null){
      // if the selected caller is STILL null, set to the first voice available.
      // this is a one off that really would only happen if the user's browser
      // has a language that doesn't have a caller available for it.
      selectedCaller = this.voices[0];
    }
    let userLanguage = window.navigator.userLanguage || window.navigator.language;
    // loop through voices and either choose the one that matches the selection or choose the first one that matches user's language
    this.voices.forEach(voice => {
      if(selectedCaller !== null && Object.prototype.hasOwnProperty.call(selectedCaller, 'value')){
        if(voice.name === selectedCaller.value){
          this.setState({selectedCaller: voice});
        }
      } else {
        if(voice.lang === userLanguage){
          selectedCaller = voice;
        }
      }
    });
    this.setState({selectedCaller: selectedCaller});
  };

  /*
   *  Say Function
   *  Will speak any string that is passed in
   */
  say = (text) => {
    if (this.speechEnabled === true && this.state.enableCaller === true) {
      // Create a new instance of SpeechSynthesisUtterance.
      let msg = new SpeechSynthesisUtterance();
      msg.text = text;
      msg.volume = 1;
      if (Object.prototype.hasOwnProperty.call(this.state,'selectedCaller')) {
        this.voices.forEach(caller => {
          if(caller.value === this.state.selectedCaller.value){
            msg.voice = caller;
          }
        })
      }
      this.cancelSpeech();
      this.synth.speak(msg);
    }
  };

  /**
   * Cancel speech function
   * Will cancel any existing speech
   */
  cancelSpeech = () => {
    if(window.speechSynthesis.speaking){
      window.speechSynthesis.cancel();
    }
  };

  /**
   * Handles the audible call of the ball
   *
   * @param   {Object}  ball  Object representing a ball
   */
  voiceCall = (ball) => {
    // call the new ball, first call it all together, then call each character individually
    let ballstring = ball.number.toString();
    let callGroup = [ball.number, ' ', ' ', ' '];
    if(this.state.doubleCall && this.state.extraTalk){
      callGroup.push(ballstring.length === 2 ? [ballstring.charAt(0), ' ', ballstring.charAt(1)] : ball.number, ' ', ' ', ' ')
      callGroup.push(getPhrase(ball.number));
    } else {
      if(this.state.doubleCall){
        callGroup.push(ballstring.length === 2 ? [ballstring.charAt(0), ' ', ballstring.charAt(1)] : ball.number)
      }
      if(this.state.extraTalk){
        callGroup.push(getPhrase(ball.number));
      }
    }
    this.say(callGroup);
  }

  /**
   * Handles a wild ball call when the wild bingo game mode is active
   *
   * @param   {Object}  ball  Object representing a ball
   */
  wildBallCall = (ball) => {
    // call the wild ball, 
    let ballstring = ball.number.toString();
    if(this.state.extraTalk){
      if(this.state.evensOdds){
        window.setTimeout(() => {
          this.say(['The wild number ', ' ', ball.number, ' ', ' ', ` mark every ${(ball.number % 2) === 1 ? 'odd number' : 'even number'}`])
        },2000);
      } else {
        window.setTimeout(() => {
          this.say(['The wild number ', ' ', ball.number, ' ', ' ', ` mark every number ending in ${ballstring.substr(-1)}`])
        },2000);
      }
    } else {
      if(this.state.doubleCall){
        this.say([ball.number, ' ', ' ',
        (ballstring.length === 2 ? [ballstring.charAt(0), ' ', ballstring.charAt(1)] : ball.number)]);
      } else {
        this.say([ball.number]);
      }
    }
  }


  /* ------------------- Gameplay Functions */

  startNewGame = () => {
    // Obtain all randomized balls
    let byteArray = new Uint8Array(1);
    let randomVals = [];
    
    while(randomVals.length < 90){
      let randomVal = window.crypto.getRandomValues(byteArray)[0];
      if(randomVal > 0 && randomVal <= 90 && !randomVals.includes(randomVal)){
        randomVals.push(randomVal)
      }
    }

    // Start with the Let's Play Bingo call out 
    // (the .say method will not run if caller is not enabled)
    if(this.state.wildBingo){
      if(this.state.enableCaller && this.state.extraTalk){
        this.say("Vamos jogar Bingo!");
        window.setTimeout(() => {
          this.startWildBingo();
        }, 2000)
      } else {
        this.startWildBingo();
      }
    } else {
      if(this.state.enableCaller){
        if(this.state.extraTalk){
          this.say("Vamos jogar Bingo!");
          window.setTimeout(() => {this.callBingoNumber();},2000);
        } else {
          this.callBingoNumber();
        }
      } else {
        this.callBingoNumber();
      }
    }
  }

  startNewAutoplayGame = () => {
    if(this.state.wildBingo){
      this.startNewGame();
    } else {
      if(this.state.enableCaller){
        if(this.state.extraTalk){
          this.say("Vamos começar o Bingo!");
          window.setTimeout(()=> {
            this.toggleGame();
          },2000);
        } else {
          this.toggleGame();
        }
      } else {
        this.toggleGame();
      }
    }
  }

  startWildBingo = () => {
    // Variables used for wild bingo
    let randomBingoNumber = getRandomBingoNumber();
    let wildNumber = randomBingoNumber.toString().slice(-1);
    let odd = (wildNumber % 2) === 1;
    let wildBall = null;
    let lastBall = null;
    let board = this.state.board;
    let totalBallsCalled = this.totalBallsCalled;
    let previousCallList = this.state.previousCallList.length > 0 ? [...this.state.previousCallList] : [];

    Object.keys(board).forEach(column => {
      board[column].forEach(number => {
        if(!number.called){
          if(number.number === randomBingoNumber){
            this.setState({wildBall: (randomBingoNumber)});
            number.called = true;
            number.active = true;
            wildBall = number;
            if(this.state.enableCaller){
              this.wildBallCall(number);
            }
            totalBallsCalled++;
            previousCallList.push(number);
          } else if(!this.state.evensOdds && number.number.toString().slice(-1) === wildNumber){
            lastBall = number;
            number.called = true;
            totalBallsCalled++;
            previousCallList.push(number);
          } else if(this.state.evensOdds && ((number.number % 2 === 1) === odd)){
            lastBall = number;
            number.called = true;
            totalBallsCalled++;
            previousCallList.push(number);
          }
        }
        return number;
      });
      return column;
    });

    this.totalBallsCalled = totalBallsCalled;
    this.previousBall = lastBall;
    this.currentBall = wildBall;
    this.setState({board: board, previousCallList: [...previousCallList]});
  }

  toggleGame = () => {
    let running = this.state.running;
    if(running === true){
      clearInterval(this.interval);
    } else {
      this.callBingoNumber();
      this.interval = setInterval(this.callBingoNumber, 8000);
    }
    this.setState({running: !running});
  }

  toggleResetModal = () => {
    const currentState = this.state.showResetModal;
    this.setState({showResetModal: !currentState})
  }

  confirmResetGame = () => {
    // Clear out local storage
    localStorage.removeItem('lpb90-gameData');
    localStorage.removeItem('lpb90-gameState');
    // reset everything with the board
    clearInterval(this.interval);
    this.cancelSpeech();
    this.totalBallsCalled = 0;
    window.location.reload();
    window.winners = [];
    this.previousBall = null;
    this.currentBall = null;
    this.setState({board: generateBingoBoard(), wildBall: null, running: false, showResetModal: false, previousCallList: []})
  }
  

  callBingoNumber = () => {
    let primeiravez = this.primeiravez;
    let totalBallsCalled = this.totalBallsCalled;
    let cartelas = this.cartelas;
    let chamadas = this.chamadas;
    if(totalBallsCalled < 90){
      let board = this.state.board;
      let currentBall = null;
      let previousBall = this.currentBall;
      let randomBingoNumber = getRandomBingoNumber();
      let callAgain = false;
      let updateState = false;
      let previousCallList = [...this.state.previousCallList];
  
      // Map through the columns on the board
      Object.keys(board).map(column => {
        // Map through each number 1-18 under each column on the board
        board[column].map((number)=>{
          // automatically set the number as not active (this will clear any previously active numbers)
          number.active = false;
          // If this is the match to the random number we called, do logic
          if(number.number === randomBingoNumber){
            // if the number was not called, do logic. Else call again
            if(!number.called){
              // increment the total balls called.
              totalBallsCalled++;
              // set to called and add to previously called numbers
              number.called = true;
              previousCallList.push(number);
              if (number.number){
                chamadas.push(number.number);
              }
              
             
              //alert(number.number);
              /* eslint-disable no-console */ 
              console.log(primeiravez);
              cartelas = cartelas.map(x => x.filter(x => !chamadas.includes(x)));
              // Gets winners
              window.winners = cartelas.map(x => x.length === 1 ? x : []).flatMap(x=>x);

              currentBall = number;
             
              // set ball to active since we won't be calling again
              number.active = true;

              //If chime is enabled, play the chime
              if(this.state.chime){
                let chime = new Audio(this.state.selectedChime.value);
                chime.play();
              }

              

              if (window.winners.length > 0 && primeiravez == true){
                this.primeiravez = false;
                const moedas = new Audio(moedasmp3);
                moedas.play();
                setTimeout(() => {
                  this.voiceCall(number);
                },3000);
              }else{
                setTimeout(() => {
                  this.voiceCall(number);
                },1000);
              }

              updateState = true;
              this.totalBallsCalled = totalBallsCalled;
            } else {
              // call again cause we got a ball we already called
              callAgain = true;
            }
          }
          return number;
        })
        return column;
      })

      if(updateState){
        this.previousBall = previousBall;
        this.currentBall = currentBall;
        this.setState({board: board, previousCallList: previousCallList});
      }
      if(callAgain && totalBallsCalled < 90){
        this.callBingoNumber();
      }
    } else {
      clearInterval(this.interval);
      this.totalBallsCalled = 90;
      this.say("É melhor alguém ter feito bingo porque as bolas acabaram!");
      this.previousBall = this.currentBall;
      this.currentBall = null;
      this.setState({running: false});
    }
  }

  /* ------------------ Handlers */
  handleDelayChange = (e) => {
    if(this.state.running === true){
      clearInterval(this.interval);
      this.interval = setInterval(this.callBingoNumber, 10000);
    }
    this.setState({delay: 10000});
  }

  handleCheckbox = (e) => {
    let gamemode = e.currentTarget.dataset.gamemode;
    switch(gamemode){
      case 'enable-doublecall':
        this.setState({doubleCall: e.currentTarget.checked});
        break;
      case 'enable-extratalk':
        this.setState({extraTalk: e.currentTarget.checked});
        break;
      case 'wild-bingo':
        this.setState({wildBingo: e.currentTarget.checked});
        break;
        case 'evens-odds':
          this.setState({evensOdds: e.currentTarget.checked});
          break;
      case 'enable-caller':
        if(this.synth.speaking){
          this.cancelSpeech();
        }
        this.setState({enableCaller: e.currentTarget.checked});
        break;
      case 'display-board':
        if(e.currentTarget.checked && this.state.running){
          clearInterval(this.interval);
        }
        this.setState({displayBoardOnly: e.currentTarget.checked, running: false});
        break;
      case 'enable-chime':
        this.setState({chime: e.currentTarget.checked});
        break;
      default:
        break;
    }
  }

  /* ------------------- JSX Display Functions */
  
  /**
   * Returns a JSX element to display the current ball
   *
   * @return  {JSX}  JSX Element
   */
  get currentBallDisplay(){
    return this.currentBall !== null ? getBallDisplay(this.currentBall) : getLogoBallDisplay();
  }

  /**
   * Get Number Display 
   *
   * @return  {JSX}  html element
   */
  get numberDisplay() {
    let numbers = this.totalBallsCalled.toString().split('');
    if(numbers.length === 1){
      return <div><span>&nbsp;</span><span>{numbers[0]}</span></div>
    } else {
      return numbers.map((number, index) => (
        <span key={"numDisplay" + number + index}>{number}</span>
      ))
    }
  }

  /**
   * Get the current call display
   *
   * @return  {JSX}  html element
   */
  get currentCallDisplay() {
    const currentCall = this.currentBall;
    if(currentCall){
      let numbers = ["0"];
      if(Object.prototype.hasOwnProperty.call(currentCall,'number')){
        numbers = currentCall.number.toString().split('');
      }
      if(numbers.length === 1){
        return <div><span>&nbsp;</span><span>{numbers[0]}</span></div>
      } else {
        return numbers.map((number, index) => (
          <span key={"call" + number + index}>{number}</span>
        ))
      }
    } else {
      return <div><span>&nbsp;</span><span>&nbsp;</span></div>
    }
  }


  /**
   * Get the previous call display
   *
   * @return  {JSX}  html element
   */
   get previousCallDisplay() {
    const previousCall = this.previousBall;
    if(previousCall){
      let numbers = ["0"];
      if(Object.prototype.hasOwnProperty.call(previousCall,'number')){
        numbers = previousCall.number.toString().split('');
      }
      if(numbers.length === 1){
        return <div><span>&nbsp;</span><span>{numbers[0]}</span></div>
      } else {
        return numbers.map((number, index) => (
          <span key={"call" + number + index}>{number}</span>
        ))
      }
    } else {
      return <div><span>&nbsp;</span><span>&nbsp;</span></div>
    }
  }

  /**
   * Reset confirmation modal display
   *
   * @return  {[JSX]}  Return modal or empty div
   */
  get resetConfirmationModalDisplay() {
    if(this.state.showResetModal === true){
      return (
        <div>
          <div className="modal">
            <h4>Zerar Jogo</h4>
            <p>Tem certeza que deseja zerar o jogo?</p>
            <p className="red-text">Esta ação <strong>não poderá</strong> ser desfeita.</p>
            <p>
              <button onClick={this.toggleResetModal}>Cancelar</button>
              <button className="primaryBtn" onClick={this.confirmResetGame}>Confirmar</button>
            </p>
          </div>
          <div className="modal-backdrop" onClick={(e) => {e.preventDefault();}}></div>
        </div>
      )
    } else {
      return null
    }
  }

  /* ------------------- Speech Synthesis */
  
  /**
   * Returns the options for the voice selection menu
   *
   * @return  {Array}  Options array
   */
  get voiceOptions(){
    let voiceOptions = [];
    if(this.speechEnabled === true){
      this.voices.forEach(voice => {
        let voiceObj = voice;
        voiceObj.value = voice.name;
        voiceObj.label = voice.name + ' / ' + getLanguageText(voice.lang);
        voiceOptions.push(voiceObj);
      })
    }
    return voiceOptions;
  }

  /*
  *  Choose Caller Function
  *  This sets the selected caller
  */
  handleChooseCaller = (e) => {
    this.setState({selectedCaller: e})
  };

  /**
   * Choose Chime Function
   * Sets the selected chime audible
   *
   * @param   {event}  e  Event
   */
  handleChooseChime = (e) => {
    let chime = new Audio(e.value);
    chime.play();
    this.setState({selectedChime: e})
  }

  /* ------------------- Display Board Only Mode */
  manualCall = (ball) => {
    let board = this.state.board;
    let currentBall = null;
    let previousBall = this.currentBall;
    let totalBallsCalled = this.totalBallsCalled;
    let previousCallList = [...this.state.previousCallList];
    Object.keys(board).forEach(column => {
      board[column].forEach(number => {
        number.active = false;
        if(ball.number === number.number){
          if(number.called){
            number.called = false;
            totalBallsCalled--;
            previousCallList = previousCallList.map((previousBall) => {return previousBall !== ball});
            previousBall = previousCallList[previousCallList.length - 1];
          } else {
            previousCallList.push(number);
            number.called = true;
            number.active = true;
            totalBallsCalled++;
            currentBall = number;
          }
        }
        return number;
      })
      return column;
    })
    this.totalBallsCalled = totalBallsCalled;
    this.previousBall = previousBall;
    this.currentBall = currentBall;
    this.setState({board: board, previousCallList});
  }

  /**
   * Sends an email that contains game 
   * settings and device info to help with
   * replicating user issues
   */
  handleBugReport = () => {
    let subject = 'Let\'s Play Bingo bug report';
    let body = `Thank you for playing let's play bingo and for taking the time to report a bug! Please describe what is happening to you so I may fix it ASAP.`;
    body += `%0D%0A%0D%0A%0D%0A -------------------------------- PLEASE LEAVE EVERYTHING BELOW THIS LINE IN TACT --------------------------------`;
    body += `%0D%0A%0D%0A The data below includes information about your device and your game settings. This information will help me replicate your issue so I can fix it.`;
    body += `%0D%0A%0D%0A----- Browser/Device Info ------ %0D%0A`;
    const {userAgent} = navigator;
    body += JSON.stringify(userAgent);
    body += `%0D%0A%0D%0A----- Game State ------ %0D%0A`;
    let gameData = this.state;
    body += JSON.stringify(gameData);
    window.open(`mailto:hello@letsplaybingo.io?subject=${subject}&body=${body}`);
  }


  /* ------------------- Render */
  render(){
    return(
      <div className="dark-bg light-links">
        {/* ----------- Bingo Board ------------- */}
        <section className="board-block">
          <div className="container row no-wrap align-stretch">
            {/* ------ Board ------- */}
            <div className="col call-side shrink padding-xlg">
               {/* ----------- Current Ball Display ------------- */}
              <div className="col min-size-250 padding-vertical-xxlg padding-horizontal-md notranslate">
                {this.currentBallDisplay}

                <CallHistory calledBalls={this.state.previousCallList}></CallHistory>
           

                <div data-visibility={this.state.wildBingo ? "show" : "hide"} className="white-text text-center margin-top-lg">
                  <strong>Wild Ball: </strong> {this.state.wildBall}
                </div>
              </div>
            </div>
            <div className="col board-side">
              <BingoBoard board={this.state.board} manualMode={this.state.displayBoardOnly} manualCall={this.manualCall} />
            </div>

          </div>
        </section>


        {/* ----------- BOTTOM SECTION ------------- */}
        <section className="game-controls dark-bg">
          <div className="container row justify-start align-start">

           
            {/* ----------- Gameplay Controls ------------- */}
            <div className="col shrink padding-vertical-xxlg padding-horizontal-md">
              <section className="gameplay-controls">
                <div data-disabled={this.totalBallsCalled >= 90}>
                  <button data-disabled={this.state.displayBoardOnly} onClick={this.totalBallsCalled === 0 ? this.startNewGame : this.callBingoNumber} disabled={this.state.running}>
                    {this.totalBallsCalled === 0 ? "Novo Jogo" : "Sortear Número"}
                  </button>

                  <button data-disabled={this.state.displayBoardOnly} data-newgame={this.totalBallsCalled === 0}
                    onClick={this.totalBallsCalled === 0 ? this.startNewAutoplayGame : this.toggleGame}>
                      {this.state.running ? "Pausar" : "Iniciar"}
                  </button>
                </div>

                <button onClick={this.toggleResetModal} disabled={this.state.running || this.totalBallsCalled === 0}>
                  Zerar
                </button>

                
              </section>
              {this.resetConfirmationModalDisplay}
            </div>

            {/* ----------- Game Settings ------------- */}
            <div className="col grow no-wrap padding-vertical-xxlg padding-horizontal-md white-text" data-visibility="hide">
              <section className="game-settings">

                {/* ----------- Gameplay Settings ---------- */}
                <div className="row align-top justify-start">
                  <div className="col shrink min-size-150 padding-horizontal-lg padding-vertical-md">
                    <h6>Gameplay Settings:</h6>
                  </div>
                  <div className="col grow min-size-150 padding-horizontal-lg">
                    <div className="row">
                      <div className="col grow" data-disabled={this.totalBallsCalled > 0}>
                        <label className={this.state.displayBoardOnly ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span>Manual Calling Mode</span>
                          <input type="checkbox" data-gamemode="display-board" onChange={this.handleCheckbox} checked={this.state.displayBoardOnly}></input>
                        </label>
                      </div>
                    </div>
                    <div className="row justify-start" data-visibility={this.state.displayBoardOnly === false ? "show" : "hide"}>
                      <div className="col padding-right-xlg" data-disabled={this.totalBallsCalled > 0}>
                        <label className={this.state.wildBingo ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span>Wild Bingo</span>
                          <input type="checkbox" data-gamemode="wild-bingo" onChange={this.handleCheckbox} checked={this.state.wildBingo}></input>
                        </label>
                      </div>
                      <div className="col padding-right-xlg" data-disabled={!this.state.wildBingo || this.totalBallsCalled > 0}>
                        <label className={this.state.evensOdds ? 'toggle checked' : 'toggle'}>
                          <span className="toggle-span"></span>
                          <span>Evens/Odds</span>
                          <input type="checkbox" data-gamemode="evens-odds" onChange={this.handleCheckbox} checked={this.state.evensOdds}></input>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ----------- Settings when using generation ---------- */}
                <div  data-visibility={this.state.displayBoardOnly === false ? "show" : "hide"}>

                  {/* ----------- Autoplay Settings ---------- */}
                  <div className="row no-wrap align-center justify-start">
                    <div className="col shrink min-size-150 padding-horizontal-lg">
                      <h6>Autoplay Speed:</h6>
                    </div>
                    <div className="col shrink text-center padding-vertical-lg padding-horizontal-lg">
                      <div className="row no-wrap align-center slider" data-disabled={this.state.displayBoardOnly}>
                        <div className="col shrink padding-right-lg white-text">Slower</div>
                        <div className="col"><Slider min={3500} max={30000} step={500} value={this.state.delay} onChange={this.handleDelayChange} reverse={true} /></div>
                        <div className="col shrink padding-left-lg white-text">Faster</div>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Caller ---------- */}
                  <div className="row align-start justify-start">
                    <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                      <h6>Audible Caller:</h6>
                    </div>
                    <div className="col grow min-size-150 padding-horizontal-lg">
                      {/* Disabled if manual calling mode is on */}
                      <div className="row no-wrap justify-start" data-visibility={this.speechEnabled === true ? "show" : "hide"}>
                        {/* Only shown if speech is enabled by the browser */}
                        <div className="col shrink padding-right-xlg">
                          <label className={this.state.enableCaller ? 'toggle checked' : 'toggle'}>
                            <span className="toggle-span"></span>
                            <span>Enable</span>
                            <input type="checkbox" data-gamemode="enable-caller" onChange={this.handleCheckbox} checked={this.state.enableCaller}></input>
                          </label>
                        </div>
                        <div className="col shrink padding-right-xlg mobile-no-horizontal-padding" data-visibility={this.state.enableCaller ? "show" : "hide"}>
                          <label className={this.state.doubleCall ? 'toggle checked' : 'toggle'}>
                            <span className="toggle-span"></span>
                            <span>Double Call</span>
                            <input type="checkbox" data-gamemode="enable-doublecall" onChange={this.handleCheckbox} checked={this.state.doubleCall}></input>
                          </label>
                        </div>
                        <div className="col shrink padding-right-xlg mobile-no-horizontal-padding" data-visibility={this.state.enableCaller ? "show" : "hide"}>
                          <label className={this.state.extraTalk ? 'toggle checked' : 'toggle'}>
                            <span className="toggle-span"></span>
                            <span>Chatty</span>
                            <input type="checkbox" data-gamemode="enable-extratalk" onChange={this.handleCheckbox} checked={this.state.extraTalk}></input>
                          </label>
                        </div>
                      </div>

                      {/* Only shown if speech is DISABLED by the browser */}
                      <div className="row no-wrap" data-visibility={this.speechEnabled === true ? "hide" : "show"}>
                        <div className="col grow">Sorry, but your browser does not support the audible bingo caller.</div>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Caller Selection ----------- */}
                  <div className="row align-start justify-start" data-visibility={this.speechEnabled === true && this.state.enableCaller === true ? "show" : "hide"}>
                    <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                      <h6>Caller Selection:</h6>
                    </div>
                    <div className="col grow min-size-150 padding-horizontal-lg">
                      <Select 
                        className="select-input"
                        placeholder="Choose Caller"
                        menuPlacement="auto"
                        value={this.state.selectedCaller}
                        onChange={this.handleChooseCaller}
                        options={this.voiceOptions}
                      />
                    </div>
                  </div>

                  {/* ----------- Chime ----------- */}
                  <div className="row no-wrap align-start justify-start">
                    <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                      <h6>Audible Chime:</h6>
                    </div>

                    <div className="col grow padding-horizontal-lg">
                      <label className={this.state.chime ? 'toggle checked' : 'toggle'}>
                        <span className="toggle-span"></span>
                        <span>Enable</span>
                        <input type="checkbox" data-gamemode="enable-chime" onChange={this.handleCheckbox} checked={this.state.chime}></input>
                      </label>
                    </div>  
                  </div>

                  {/* ----------- Chime Selection ----------- */}
                  <div className="row no-wrap align-start justify-start"  data-visibility={this.state.chime ? "show" : "hide"}>
                    <div className="col shrink min-size-150 padding-vertical-md padding-horizontal-lg">
                      <h6>Chime Selection:</h6>
                    </div>

                    <div className="col grow padding-horizontal-lg">
                      <Select 
                          className="select-input"
                          placeholder="Choose Chime"
                          menuPlacement="auto"
                          value={this.state.selectedChime}
                          onChange={this.handleChooseChime}
                          options={this.chimes}
                        />
                    </div>  
                  </div>

                </div>
              </section>
            </div>


            {/* ----------- Info ------------- */}
            

          </div>
        </section>
      </div>
    )
  }
}

export default BingoGame;