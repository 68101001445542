import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';
// Styles
import './sass/main.scss';
// Custom Components
import BingoGame from './components/BingoGame.js';

const routing = (
  <Router>
    <Route exact path="/" component={BingoGame} />
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'));